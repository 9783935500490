<template>
  <div class="app-container">
    <eHeader
            :query="query"
            :sites="sites"
            :sup_this="this"
            @query="queryHandle" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="id" width="60px"/>
      <el-table-column prop="siteName" label="站点名称" width="200px"/>
      <el-table-column prop="templateName" label="模板名称" />
      <el-table-column prop="subject" label="主题" />
      <el-table-column prop="lang" label="语言/language">
        <template slot-scope="scope">
          <span>{{getLang(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MAILTEMPLATE_ALL','MAILTEMPLATE_EDIT'])" :data="scope.row" :sup_this="sup_this" :sites="sites"/>
          <el-popover
            v-if="checkPermission(['ADMIN','MAILTEMPLATE_ALL','MAILTEMPLATE_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180"
          >
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button
                :loading="delLoading"
                type="primary"
                size="mini"
                @click="subDelete(scope.row.id)"
              >确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { getSitesData } from "@/api/cms/srSite";
import { del } from "@/api/tools/tMailTemplate";
import { parseTime } from "@/utils/index";
import eHeader from "@/components/tools/mailtemplate/header";
import edit from "@/components/tools/mailtemplate/edit";
export default {
  name:'mailtemplate',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      sites: [],
    };
  },
  created() {
    this.getSites();
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "crm/tMailTemplate";
      const sort = "id,desc";
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        lang: this.query.language
      };
      const query = this.query;
      const siteId = query.siteId;
      const templateName = query.templateName;
      if (siteId !== "" && siteId !== null) {
        this.params["siteId"] = siteId;
      }
      if (templateName !== "" && templateName !== null) {
        this.params["templateName"] = templateName;
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    getSites() {
      const params = {page:0,size:200}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    getLang(row){
      switch (row.lang){
        case 'zh_CN':
          return '中文';
        case 'en_US':
          return '英文';
        case 'zh_Hant':
          return '繁体';
      }
    },
    queryHandle(){
      this.page = 0;
      this.init();
    }
  }
};
</script>

<style scoped>
</style>
